import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import SearchBar from './SearchBar';
import { KTIcon } from '../../../_metronic/helpers';
import { LinksListPagination } from './LinksListPagination';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import DevicesFilter from './DevicesFilter';
import Cookies from 'js-cookie';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';

const Classification = () => {
    const [loading, setLoading] = useState(true);
    const [loadSort, setLoadSort] = useState(true);
    const [allData, setAllData] = useState([]);
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState(location.state ? location.state.searchTerm : '');
    const [currentPage, setCurrentPage] = useState(location.state ? location.state.currentPage : 1);
    // const [filtered, setFiltered] = useState(allData);
    // const totalItems = filtered?.length || allData.length;
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);
    // const totalPages = Math.ceil(totalItems / itemsPerPage);
    // const [classification, setClassification] = useState([]);
    const interests = useSelector(state => state.account.interests);
    const [sortConfig, setSortConfig] = useState({ key: 'product_code', direction: 'asc' });
    const [showAll, setShowAll] = useState({});
    // const [filterDeviceClass, setFilterDeviceClass] = useState('');
    // const [filterRegulationNumber, setFilterRegulationNumber] = useState('');
    // const [filterKNumber, setFilterKNumber] = useState('');
    // const [filterProductCode, setFilterProductCode] = useState('');
    // const [filterMedicalSpecialty, setFilterMedicalSpecialty] = useState('');
    const navigate = useNavigate();
    const [totalPages, setTotalPages] = useState(0);
    const [committees, setCommittees] = useState({});
    const firstRenderSearchTerm = useRef(true);
    const [specialityOptions] = useState(interests.committees);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [selectedDeviceClasses, setSelectedDeviceClasses] = useState(interests.device_classes);
    const handleDeviceClassChange = (event) => {
        const { value, checked } = event.target;
        let newValue = value === 'Class 1' ? 1 : value === 'Class 2' ? 2 : 3;
        setSelectedDeviceClasses(currentClasses => {
            if (checked) {
                if (!currentClasses.includes(newValue)) {
                    return [...currentClasses, newValue];
                }
                return currentClasses;
            } else {
                return currentClasses.filter(item => item !== newValue);
            }
        });
    };

    const [specialityDropdownOpen, setSpecialityDropdownOpen] = useState(false);
    const toggleSpecialityDropdown = () => setSpecialityDropdownOpen(!specialityDropdownOpen);
    const [selectedSpecialities, setSelectedSpecialities] = React.useState(interests.committees);
    const prevSearchInputRef = useRef();

    const handleSpecialityChange = (event) => {
        const { value, checked } = event.target;

        // Handle "All" option separately
        if (value === 'All') {
            if (checked) {
                setSelectedSpecialities(specialityOptions); // Select all options
            }
            else {
                setSelectedSpecialities([]); // Deselect all options
            }
        } else {
            setSelectedSpecialities((prevSelectedSpecialities) => {
                if (checked && !prevSelectedSpecialities.includes(value)) {
                    // If selecting an option and all other options are selected, add "All" to the selection
                    const allSelected = [...prevSelectedSpecialities, value].length === specialityOptions.length;
                    return allSelected ? [...prevSelectedSpecialities, value, 'All'] : [...prevSelectedSpecialities, value];
                } else if (!checked) {
                    // If deselecting an option, also remove "All" from the selection
                    return prevSelectedSpecialities.filter((speciality) => speciality !== value && speciality !== 'All');
                }
                return prevSelectedSpecialities;
            });
        }
    };

    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);


    const dropdownRef2 = useRef(null);
    const handleClickOutsideSpeciality = (event) => {
        if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setSpecialityDropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (specialityDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutsideSpeciality);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideSpeciality);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSpeciality);
        };
    }, [specialityDropdownOpen]);

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedDeviceClasses, selectedSpecialities]);

    // useEffect(() => {
    //     setTotalPages(Math.ceil(totalItems / Math.max(1, itemsPerPage)));
    //     if (currentPage > totalPages) {
    //         setCurrentPage(1);
    //     }
    // }, [totalItems, currentPage, totalPages, itemsPerPage]);

    // useEffect(() => {
    //     const fetchClassification = async () => {
    //         setLoading(true);
    //         try {
    //             const response = await axios.get(`/reg/v1/fda/classification`);
    //             let data = response.data;

    //             // if (interests.committees.length > 0 || interests.device_classes.length > 0) {
    //             //     data = data.filter(device =>
    //             //         (interests.committees.length === 0 || interests.committees.some(committee => {
    //             //             const code = committee.split('(')[0].trim();
    //             //             return code === device.medical_specialty_description;
    //             //         })) &&
    //             //         (interests.device_classes.length === 0 || interests.device_classes.includes(device.device_class))
    //             //     );
    //             // }

    //             setAllData(data);
    //         }
    //         catch (error) {
    //             console.error(error);
    //         }
    //         setLoading(false);
    //     };
    //     fetchClassification();
    // }, [interests]);

    useEffect(() => {
        if (firstRenderSearchTerm.current) {
            firstRenderSearchTerm.current = false;
            return;
        }

        const handler = setTimeout(() => {
            setCurrentPage(1);
            if (searchTerm.trim() !== '') {
                setSortConfig({ key: '', direction: '' });
            } else {
                setSortConfig({ key: 'product_code', direction: 'asc' });
            }
        }, 0);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    useEffect(() => {
        prevSearchInputRef.current = searchTerm;
    }, [searchTerm]);

    const debounceDelay = searchTerm.trim() === '' ? 0 : (prevSearchInputRef.current === searchTerm ? 1000 : 3000);


    useEffect(() => {

        const delayDebounceFn = setTimeout(async () => {
            const fetchNewDevices = async () => {
                setLoading(true);
                //setLoadSort(true);

                const committees = selectedSpecialities.map(item => {
                    const match = item.match(/\(([^)]+)\)/);
                    return match ? match[1] : null;
                }).filter(Boolean);

                const sortedCommittees = committees.sort((a, b) => a.localeCompare(b));
                const sortedDeviceClasses = selectedDeviceClasses.sort((a, b) => a - b);

                const filters = { device_classes: sortedDeviceClasses, committees: sortedCommittees };
                const filtersJson = JSON.stringify(filters);

                try {
                    if (searchTerm.trim() === '') {
                        const response = await axios.post(
                            `/reg/v1/fda/preference/classification/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortConfig.key}&sort_order=${sortConfig.direction}`,
                            { filter: filtersJson },
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                        if (response.data) {
                            setAllData(response.data);
                            // console.log(response.data);
                            setTotalPages(Math.ceil(response.data[0]?.total_count / Math.max(1, itemsPerPage)));
                            // console.log(response.data[0]?.product_count);
                        }
                    } else {
                        const response = await axios.post(
                            `/reg/v1/search/search/classification?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=1&sort_order=1&search_string=${searchTerm}`,
                            { filter: filtersJson },
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                        if (response.data) {
                            setAllData(response.data);
                            setTotalPages(Math.ceil(response.data[0]?.search_count / Math.max(1, itemsPerPage)));
                        }
                    }
                } catch (error) {
                    console.error("Failed to fetch new devices:", error);
                } finally {
                    setLoading(false);
                    setLoadSort(false);
                }
            };

            fetchNewDevices();
        }, debounceDelay);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, itemsPerPage, sortConfig.direction, sortConfig.key, searchTerm, selectedSpecialities, selectedDeviceClasses]);




    // useEffect(() => {
    //     window.scrollTo(0, document.body.scrollHeight);
    // }, [currentPage]);

    useEffect(() => {
        if (interests) {
            const newCommittees = {};
            interests.committees.forEach(committee => {
                const match = committee ? committee.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newCommittees[key] = value;
                }
            });
            setCommittees(newCommittees);
        }
    }, [interests]);

    // useEffect(() => {
    //     const fetchCommittees = () => {
    //         axios.get('/reg/v1/fda/advisory_committee')
    //             .then(response => {
    //                 const options = response.data.map(committee =>
    //                     `${committee.description} (${committee.code})`,
    //                 );
    //                 setSpecialityOptions(options);
    //             });
    //     };
    //     fetchCommittees();
    // }, []);



    // const matchesCommittee = (event, committees) =>
    //     committees.some(committee => {
    //         const match = committee.match(/\(([^)]+)\)/);
    //         const code = match ? match[1] : committee;
    //         return code === event.medical_specialty_description;
    //     });


    // useEffect(() => {
    //     const filteredData = allData.filter(classification => {
    //         const matchesSearchTerm = searchTerm.trim() === '' ||
    //             classification.device_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||

    //             classification.product_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             classification.medical_specialty_description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    //             classification.regulation_number?.toLowerCase().includes(searchTerm) ||
    //             (classification.k_number && classification.k_number?.toLowerCase().includes(searchTerm.toLowerCase()));
    //         const matchesInterests = (selectedSpecialities.length === 0 || matchesCommittee(classification, selectedSpecialities)) &&
    //             (selectedDeviceClasses.length === 0 || selectedDeviceClasses.includes(Number(classification.device_class_id)));

    //         // const matchesDeviceClass = !filterDeviceClass || parseInt(classification.device_class) === parseInt(filterDeviceClass);


    //         return matchesInterests && matchesSearchTerm;
    //     });

    //     setFiltered(filteredData);

    //     setClassification(filteredData.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage));
    // }, [currentPage, searchTerm, allData, selectedSpecialities, selectedDeviceClasses, itemsPerPage]);

    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);

    const handleHeaderClick = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };



    // const sortedData = React.useMemo(() => {
    //     let sortableData = [...allData];
    //     if (sortConfig !== null) {
    //         sortableData.sort((a, b) => {
    //             if (a[sortConfig.key] < b[sortConfig.key]) {
    //                 return sortConfig.direction === 'asc' ? -1 : 1;
    //             }
    //             if (a[sortConfig.key] > b[sortConfig.key]) {
    //                 return sortConfig.direction === 'asc' ? 1 : -1;
    //             }
    //             return 0;
    //         });
    //     }
    //     return sortableData;
    // }, [allData, sortConfig]);

    // const paginatedData = sortedData.slice((currentPage - 1) * Math.max(1, itemsPerPage), currentPage * Math.max(1, itemsPerPage));

    return (
        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
            <div className='d-flex flex-column flex-column-fluid'>
                <div className="d-flex flex-row-fluid px-1">
                    <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                        <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                            <div className="d-flex align-items-center ms-4">
                                <KTIcon iconName='devices' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                Classifications
                                {/* {loading && (
                                    <div className="spinner-border text-primary ms-3" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )} */}
                            </div>
                        </h1>
                    </div>
                </div>
                <div className="d-flex flex-row-fluid px-10 align-items-center">
                    <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                </div>
                {committees && interests.device_classes && (
                    <div className="d-flex flex-row-fluid px-10">
                        {Object.keys(committees).length > 0 && (
                            <div style={{ position: 'relative', paddingRight: '7px' }}>
                                <h6 className={`d-block text-wrap mt-1`}>Specialties: {' '}
                                    {Object.entries(committees).map(([key, value], index) => (
                                        <OverlayTrigger
                                            key={index}
                                            placement="top"
                                            overlay={
                                                <Tooltip id={`tooltip-${index}`}>
                                                    {value}
                                                </Tooltip>
                                            }
                                        >
                                            <span>{index > 0 && ', '}{key}</span>
                                        </OverlayTrigger>
                                    ))}
                                </h6>
                                {interests.device_classes.length > 0 && (
                                    <div style={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '5%',
                                        bottom: '5%',
                                        width: '1px',
                                        backgroundColor: '#4d4d4d'
                                    }}></div>
                                )}
                            </div>
                        )}
                        {interests.device_classes.length > 0 && (
                            <div style={{ paddingLeft: Object.keys(committees).length === 0 ? '0px' : '7px' }}>
                                <h6 className={`d-block mt-1`}>Device Classes: {interests.device_classes.join(', ')}</h6>
                            </div>
                        )}
                    </div>
                )}



                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-6 mt-8`}>
                    <div className="col-6">
                        <SearchBar searchInput={searchTerm} setSearchInput={setSearchTerm} />
                    </div>

                    <div className="d-flex ms-3 flex-column-auto d-flex align-items-center ml-auto">
                        <div className=' dropdown ' ref={dropdownRef}>
                            <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleDropdown}
                                style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                Device Class
                            </button>
                            {dropdownOpen && (
                                <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownMenuButton'>
                                    <li style={{ margin: '10px 0', width: '150px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class1' name='deviceClass' value='Class 1' checked={selectedDeviceClasses.includes(1)} onChange={handleDeviceClassChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor='class1' style={{ fontSize: '1.1rem' }}>Class 1</label>
                                    </li>
                                    <li style={{ margin: '10px 0', width: '150px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class2' name='deviceClass' value='Class 2' checked={selectedDeviceClasses.includes(2)} onChange={handleDeviceClassChange} />
                                        <label className='form-label fw-bold ms-5 mb-2 ' htmlFor='class2' style={{ fontSize: '1.1rem' }}>Class 2</label>
                                    </li>
                                    <li style={{ margin: '10px 0', width: '150px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class3' name='deviceClass' value='Class 3' checked={selectedDeviceClasses.includes(3)} onChange={handleDeviceClassChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor='class3' style={{ fontSize: '1.1rem' }}>Class 3</label>
                                    </li>
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="d-flex ms-3 flex-column-auto d-flex align-items-center ml-auto">
                        <div className=' dropdown ' ref={dropdownRef2}>
                            <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownSpecialityButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={specialityDropdownOpen} onClick={toggleSpecialityDropdown}
                                style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}>
                                Specialty
                            </button>
                            {specialityDropdownOpen && (
                                <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownSpecialityButton' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                    <li style={{ margin: '10px 0', width: '260px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='specialityAll' name='specialityOption' value='All' checked={selectedSpecialities.length === specialityOptions.length} onChange={handleSpecialityChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor='specialityAll' style={{ fontSize: '1.1rem' }}>All</label>
                                    </li>
                                    {specialityOptions.map((option, index) => (
                                        <li key={index} style={{ margin: '10px 0', width: '260px' }}>
                                            <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id={`speciality${index}`} name='specialityOption' value={option} checked={selectedSpecialities.includes(option)} onChange={handleSpecialityChange} />
                                            <label className='form-label fw-bold ms-5 mb-2' htmlFor={`speciality${index}`} style={{ fontSize: '1.1rem' }}>{option}</label>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                    </div>
                    <div className="d-flex ms-3 flex-column-auto me-auto d-flex align-items-center">
                        <label className=" fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"

                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />


                    </div>
                </div>
                {/* {allData.length > 0 && ( */}
                <div>
                    {loading ? (<HourGlass />) : (
                        <div className='table-responsive px-5 mb-6'>
                            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                                style={{
                                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                                    'tableLayout': 'fixed'
                                }}>
                                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                                        <th style={{ whiteSpace: 'nowrap', width: '18%', marginRight: '20px', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (searchTerm.trim() === '') {
                                                    handleHeaderClick('product_code');
                                                }
                                            }}
                                        >
                                            Product Code
                                            {sortConfig.key === 'product_code' && (
                                                <span className={`sorting-icon ${sortConfig.direction}`}>
                                                    {' '}<i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                                </span>
                                            )}
                                        </th>
                                        <th
                                            className={`text-center ${sortConfig.key !== 'device_class' ? 'pe-5' : ''}`}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                width: '16%',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                if (searchTerm.trim() === '') {
                                                    handleHeaderClick('device_class_id');
                                                }
                                            }}
                                        >

                                            Device Class
                                            {sortConfig.key === 'device_class_id' && (
                                                <span className={`sorting-icon ${sortConfig.direction}`}>
                                                    {' '}<i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                                </span>
                                            )}
                                        </th>
                                        <th
                                            className={`${sortConfig.key !== 'device_name' ? 'pe-5' : ''}`}
                                            style={{
                                                whiteSpace: 'nowrap',
                                                width: '35%',
                                                marginRight: '20px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => {
                                                if (searchTerm.trim() === '') {
                                                    handleHeaderClick('device_name');
                                                }
                                            }}
                                        >

                                            Device Name
                                            {sortConfig.key === 'device_name' && (
                                                <span className={`sorting-icon ${sortConfig.direction}`}>
                                                    {' '}<i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                                </span>
                                            )}
                                        </th>
                                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '15%', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (searchTerm.trim() === '') {
                                                    handleHeaderClick('medical_specialty_description');
                                                }
                                            }}
                                        >
                                            Specialty
                                            {sortConfig.key === 'medical_specialty_description' && (
                                                <span className={`sorting-icon ${sortConfig.direction}`}>
                                                    {' '}<i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                                </span>
                                            )}
                                        </th>

                                        <th className={`text-end`} style={{ whiteSpace: 'nowrap', width: '20%', paddingRight: '20px', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (searchTerm.trim() === '') {
                                                    handleHeaderClick('regulation_number');
                                                }
                                            }}
                                        >
                                            Regulation Number
                                            {sortConfig.key === 'regulation_number' && (
                                                <span className={`sorting-icon ${sortConfig.direction}`}>
                                                    {' '}<i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                                </span>
                                            )}
                                        </th>
                                        <th style={{ whiteSpace: 'nowrap', width: '40%', cursor: 'pointer' }}
                                            onClick={() => {
                                                if (searchTerm.trim() === '') {
                                                    handleHeaderClick('k_number');
                                                }
                                            }}
                                        >
                                            K Numbers
                                            {sortConfig.key === 'k_number' && (
                                                <span className={`sorting-icon ${sortConfig.direction}`}>
                                                    {' '}<i className={`bi ${sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i>
                                                </span>
                                            )}
                                        </th>
                                        <th className="text-end" style={{ whiteSpace: 'nowrap', width: '9%', paddingRight: '25px' }}>Details</th>
                                    </tr>
                                </thead>

                                {/* <tbody>
                                    <tr>
                                        <td
                                            colSpan="7"
                                            className='text-dark fw-semibold fs-5'
                                            style={{ textAlign: 'center' }}
                                        >
                                            Loading...
                                        </td>
                                    </tr>
                                </tbody> */}
                                <tbody>
                                    {!loadSort && allData.length === 0 ? (
                                        <tr>
                                            <td
                                                colSpan="7"
                                                className='text-dark fw-semibold fs-5'
                                                style={{ textAlign: 'center' }}
                                            >
                                                No records found with selected filters. Please try again with other filters.
                                            </td>
                                        </tr>
                                    ) : (
                                        allData.map((classification) => (
                                            <tr className='align-items-center bg-hover-light-primary' key={classification.product_code}
                                                onClick={() => navigate(`/device-intelligence/classification/${classification.product_code}`, {
                                                    state: { searchTerm: searchTerm, currentPage: currentPage }
                                                })}>
                                                <td className='text-dark fw-semibold fs-6' style={{ overflowX: 'auto' }}>{classification.product_code}</td>
                                                <td className='text-center text-dark fw-semibold fs-6' style={{ overflowX: 'auto' }}>
                                                    {classification.device_class_id}
                                                </td>
                                                <td className='text-dark fw-semibold fs-6' style={{ overflowX: 'auto' }}>
                                                    {classification.device_name}
                                                </td>
                                                <td className='text-dark text-center fw-semibold fs-6' style={{ overflowX: 'auto' }}>{classification.medical_specialty_description}</td>

                                                <td className={`text-end text-dark fw-semibold fs-6  ${sortConfig.key === 'regulation_number' ? 'pe-10' : ''}`} style={{ overflowX: 'auto', paddingRight: '20px' }}>{classification.regulation_number}</td>
                                                <td className='text-hover-primary text-dark text-wrap fw-semibold fs-6' style={{ overflowX: 'auto', cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                                    <div className='d-flex align-items-center'>
                                                        <div style={{ flex: 1 }}>
                                                            {classification.k_number && classification.k_number.replace(/[[\]']+/g, '').split(', ').map((kNumber, index, arr) => (
                                                                <span key={index} style={{ display: showAll[classification.product_code] || index < 10 ? 'inline' : 'none' }}>
                                                                    <Link to={`/device-intelligence/device/${kNumber}`} target="_blank" style={{ color: 'primary' }}>{kNumber}</Link>
                                                                    {(showAll[classification.product_code] || index < 9) && index !== arr.length - 1 && ', '}
                                                                </span>
                                                            ))}
                                                        </div>
                                                        {classification.k_number && classification.k_number.split(', ').length > 10 && (
                                                            <button
                                                                type='button'
                                                                className='btn btn-sm btn-primary btn-active-light d-flex align-items-center justify-content-center border border-1 me-3'
                                                                style={{ whiteSpace: 'nowrap', padding: '4px 8px' }}
                                                                onClick={() => setShowAll({ ...showAll, [classification.product_code]: !showAll[classification.product_code] })}
                                                            >
                                                                {showAll[classification.product_code] ? 'Collapse' : 'Expand'}
                                                            </button>
                                                        )}
                                                    </div>
                                                </td>
                                                <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6' style={{ paddingRight: '25px', cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                                    <Link to={`/device-intelligence/classification/${classification.product_code}`} state={{ searchTerm: searchTerm, currentPage: currentPage }} style={{ color: 'primary' }} >
                                                        Details
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {totalPages >= 1 && (
                        <div className="d-flex justify-content-center">

                            <div className="d-flex flex-column-auto ms-4 d-flex align-items-center mb-6">
                                <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                                <input
                                    type="number"
                                    value={itemsPerPage}
                                    onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                                    className='form-control form-control-solid ms-2 border border-1'
                                    style={{ width: '90px', backgroundColor: '#f1faff' }}
                                />
                            </div>
                            <LinksListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        </div>
                    )}
                </div>
                {/* )} */}
            </div>
        </div>
    );
}
export default Classification;