import React, { useState, useEffect } from "react";
import SearchBar from "./SearchBar";
import { KTIcon } from "../../../_metronic/helpers";
import XmlFileViewer from "./XmlFileViewer";

const Title21 = () => {
    const [searchInput, setSearchInput] = useState('');
    const [debouncedInput, setDebouncedInput] = useState(''); // Debounced search value
    const [isLoading, setIsLoading] = useState(true);

    // Debouncing logic: Update `debouncedInput` after 300ms of inactivity
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedInput(searchInput); // Update debounced value
        }, 300); // Adjust the delay as needed

        // Cleanup timeout on every new input
        return () => clearTimeout(handler);
    }, [searchInput]);

    const handleSearch = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Stop form submission
            setSearchInput(event.target.value); // Update search input
        }
    };

    return (
        <div>
            <div className="d-flex flex-column px-5 py-0">
                <div className="d-flex flex-column-auto mb-2 mt-2">
                    <h1 className="d-md-block text-wrap" style={{ color: "#4d4d4d" }}>
                        <div className="d-flex align-items-center">
                            <KTIcon iconName="book-open" iconType="duotone" 
                                className="fw-bold fs-1 mx-3 text-primary" />
                            <span>Title 21: Food and Drugs</span>
                        </div>
                    </h1>
                </div>
            </div>
            <div className="row mt-0">
                <div className="col-md-5 d-flex me-15">
                    <SearchBar
                        searchInput={searchInput}
                        setSearchInput={setSearchInput}
                        handleSearch={handleSearch}
                    />
                </div>
                <div className="col-md-6 d-flex align-items-center justify-content-end px-0 p-5">
                    <div className="d-flex align-items-center ms-2 mt-1">
                        <h4 style={{ color: "#4d4d4d" }}>Amendment Date: July 2, 2024</h4>
                    </div>
                </div>
            </div>
            <div className="card p-2 mx-auto text-wrap"
                style={{
                    borderRadius: '2px',
                    boxShadow: 'none',
                    height: '100%',
                    border: 'none',
                }}>
                <XmlFileViewer
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    searchInput={debouncedInput} // Use debounced input
                />
            </div>
        </div>
    );
};

export default Title21;
