import React, { useEffect, useState } from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import SearchBar from './SearchBar';
import axios from 'axios';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
import DocumentTable from './DocumentTable';
import { DocumentsListPagination } from './DocumentListPagination';
import SortFilter from './SortFilter';



function Country({country}) {

    const [searchInput,setSearchInput] = useState('');
    const [events,setEvents] = useState([]);
    const [filteredEvents,setFilteredEvents] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [error,setError] = useState(null);
    const [itemsPerPage,setItemsPerPage] = useState(10);
    const [currentPage,setCurrentPage] = useState(1);
    const [totalPages,setTotalPages] = useState(1);
    const [sortConfig, setSortConfig] = useState({ key: 'publish_date', direction: 'desc' });
    const [sortDirection, setSortDirection] = useState('desc');


    const formatCountry = (name) => {
        if(name === 'eu') return 'EU';
        return name
            .split('-')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    

    const fetchEvents = async () =>{
        setIsLoading(true);
        try {
            const response = await axios.get(`/reg/v1/fda/international_doc/${givenCountry}`);
            setEvents(response.data);
            setFilteredEvents(response.data);
            setTotalPages(Math.ceil(response.data.length / itemsPerPage));
            
            setCurrentPage(1);
        } catch (error) {
            setError('Failed to fetch documents');
            console.error(error);
        } finally {
            setIsLoading(false);
        }
        
    };

    const changeEvents = () => {
        if (searchInput.trim() !== ''){
            const carryInput = searchInput.toLowerCase();
            const backupEvents = events.filter(event => 
                event.title.toLowerCase().includes(carryInput)
            )
            setFilteredEvents(backupEvents);
            setTotalPages(Math.ceil(backupEvents.length / itemsPerPage));
            setCurrentPage(1);
        }
        else{
            setFilteredEvents(events)
        }
    };

    useEffect(() => {
        changeEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[searchInput])

    useEffect(() => {
        fetchEvents()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[country,itemsPerPage]);

    const handleViewButtonPDFClick = () => {

    }

    const handle_source_link = (url) => {
        const hostname = new URL(url).hostname;
        const  parts = hostname.split('.');
        const newPart = parts.length > 2? parts[1] : parts[0];
        return newPart.toUpperCase();
    }

    
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;

    }
    

    const onApplySort = () => {
        if (!filteredEvents || filteredEvents.length === 0 || isLoading) {
            return;
        }

        let sortedEvents = [...filteredEvents];
        if (sortConfig.direction === 'asc') {
            sortedEvents.sort((a, b) => a[sortConfig.key] > b[sortConfig.key] ? 1 : -1);
        } else if (sortConfig.direction === 'desc') {
            sortedEvents.sort((a, b) => a[sortConfig.key] < b[sortConfig.key] ? 1 : -1);
        }
        setFilteredEvents(sortedEvents);
    };

    const onResetSort = () => {
        if (!filteredEvents || filteredEvents.length === 0 || isLoading) {
            return;
        }
        let sortedEvents = [...filteredEvents];
        sortedEvents.sort((a, b) => a.postedDate < b.postedDate ? 1 : -1);
        setSortConfig({ key: 'publish_date', direction: 'desc' });
        setFilteredEvents(sortedEvents);

    };

    const givenCountry = formatCountry(country);

  return (
    <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
        <div className='d-flex flex-column flex-column-fluid'>
            <div className="d-flex flex-row-fluid px-1">
                <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                    <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                        <div className="d-flex align-items-center ms-4">
                            <KTIcon iconName='folder' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                            <span>{givenCountry}</span>
                        </div>
                    </h1>
                </div>
            </div>
            <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-3 mt-2`}>
                <div className="col-7">
                    <SearchBar searchInput={searchInput} setSearchInput={setSearchInput} />
                </div>
                <div className="d-flex flex-column-auto d-flex align-items-center py-1">
                    <button
                        type='button'
                        className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                        data-kt-menu-trigger='click'
                        data-kt-menu-placement='bottom-end'
                        data-kt-menu-flip='top-end'
                    >
                        <KTIcon iconName={sortDirection === 'asc' ? 'arrow-up' : 'arrow-down'} iconType="solid" className='fs-2' /> Sort
                    </button>
                    <SortFilter
                        sortConfig={sortConfig}
                        setSortConfig={setSortConfig}
                        onApplySort={onApplySort}
                        onResetSort={onResetSort}
                        setSortDirection={setSortDirection}
                    />
                </div>
                <div className="d-flex flex-column-auto ms-4 d-flex align-items-center ml-auto ">
                    <label className="ms-5 fs-6 fw-bold text-gray-700">Items per page:</label>
                    <input
                        type="number"
                        value={itemsPerPage}
                        onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                        className='form-control form-control-solid ms-2 border border-1'
                        style={{ width: '90px', backgroundColor: '#f1faff' }}
                    />
                </div>
            </div>
            {isLoading ? <HourGlass /> : 
                        (<DocumentTable 
                            loading={isLoading} 
                            error={error} 
                            filteredDocuments={filteredEvents} 
                            currentPage={currentPage}
                            handle_source_link = {handle_source_link}
                            DOCUMENTS_PER_PAGE={itemsPerPage} 
                            handleViewButtonPDFClick={handleViewButtonPDFClick}
                            formatDate={formatDate} 
                            searchInput={searchInput} 
                        />)}
            {totalPages >= 1 ? (
                        <div className="d-flex justify-content-center align-items-center">

                            <div className="d-flex flex-column-auto ms-4 d-flex align-items-center mb-7">
                                <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                                <input
                                    type="number"
                                    value={itemsPerPage}
                                    onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                                    className='form-control form-control-solid ms-2 border border-1'
                                    style={{ width: '90px', backgroundColor: '#f1faff' }}
                                />
                            </div>
                            <DocumentsListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                        </div>
                    ) : (
                        <div className="mb-4"></div>
                    )
                    }
        </div>
    </div>
  )
}

export default Country