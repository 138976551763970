import React from 'react';
import { Link } from 'react-router-dom';

const DevicesTable = ({ loadSort, currentDevices, handleHeaderClick, sortConfig, navigate, searchTerm, currentPage }) => {
    return (

        <div className='table-responsive px-5 mb-5'>
            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-middle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                    'tableLayout': 'fixed'
                }}>
                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>
                        {/* <th style={{ whiteSpace: 'nowrap', width: '14%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('res_event_number')}>
                            Event Number
                            <span className={`sorting-icon ${sortConfig.key === 'res_event_number' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'res_event_number' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th style={{ whiteSpace: 'nowrap', width: '25%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('openfda_device_name');
                                }
                            }}
                        >
                            Device Name
                            <span className={`sorting-icon ${sortConfig.key === 'openfda_device_name' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda_device_name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('recalling_firm');
                                }
                            }}
                        >
                            Recalling Firm
                            <span className={`sorting-icon ${sortConfig.key === 'recalling_firm' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'recalling_firm' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>

                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '10%', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('openfda_medical_specialty_description');
                                }
                            }}
                        >
                            Specialty
                            <span className={`sorting-icon ${sortConfig.key === 'openfda_medical_specialty_description' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda_medical_specialty_description' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '13%', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('product_code');
                                }
                            }}
                        >
                            Product Code
                            <span className={`sorting-icon ${sortConfig.key === 'product_code' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_code' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ whiteSpace: 'nowrap', width: '13%', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('openfda_device_class');
                                }
                            }}
                        >
                            Device Class
                            <span className={`sorting-icon ${sortConfig.key === 'openfda_device_class' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda_device_class' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ whiteSpace: 'nowrap', width: '18%', paddingRight: '20px', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('root_cause_description');
                                }
                            }}
                        >
                            Root Cause
                            <span className={`sorting-icon ${sortConfig.key === 'root_cause_description' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'root_cause_description' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        {/* <th style={{ whiteSpace: 'nowrap', width: '9%', paddingRight: '20px', cursor: 'pointer' }} onClick={() => handleHeaderClick('recall_status')}>
                            Status
                            <span className={`sorting-icon ${sortConfig.key === 'recall_status' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'recall_status' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th className='text-center' style={{ whiteSpace: 'nowrap', width: '15%', paddingRight: '10px', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('product_res_number');
                                }
                            }}
                        >
                            Recall Number
                            <span className={`sorting-icon ${sortConfig.key === 'product_res_number' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_res_number' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className={`text-end ${sortConfig.key !== 'initiated_date' ? 'pe-5' : ''}`} style={{ whiteSpace: 'nowrap', width: '11%', cursor: 'pointer' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('initiated_date');
                                }
                            }}
                        >
                            Date Posted
                            <span className={`sorting-icon ${sortConfig.key === 'initiated_date' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'initiated_date' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-end' style={{ whiteSpace: 'nowrap', width: '10%' }}>
                            Details
                        </th>
                    </tr>
                </thead>
                {loadSort && (
                    <tbody>
                        <tr>
                            <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>Loading...</td>
                        </tr>
                    </tbody>
                )}
                {!loadSort && (
                    <tbody>
                        {(currentDevices.length === 0 && !loadSort) ? (<tr>
                            <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                        </tr>
                        ) : (
                            currentDevices.map((recall, index) => (
                                <tr className='align-items-center bg-hover-light-primary' onClick={() => navigate(`/device-intelligence/recalls/${recall.product_res_number}`, { state: { searchTerm: searchTerm, currentPage: currentPage } })}>
                                    {/* <td style={{ verticalAlign: 'middle' }} className='text-dark fw-semibold fs-6' onClick={(e) => e.preventDefault()}>
                                        {recall.res_event_number}
                                    </td> */}
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.openfda_device_name}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.recalling_firm}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.code}
                                        </a>
                                    </td>
                                    <td className="text-center text-dark text-wrap fw-semibold fs-6 ps-4" style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link to={`/device-intelligence/classification/${recall.product_code}`} target="_blank" style={{ color: 'primary' }} className='align-items-center'>{recall.product_code}</Link>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.openfda_device_class}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.root_cause_description}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/recalls' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.product_res_number}
                                        </a>
                                    </td>
                                    {/* <td>
                                        <a href='/device-intelligence/recalls' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {recall.recall_status}
                                        </a>
                                    </td> */}
                                    <td className='text-end'>
                                        <a href='/device-intelligence/recalls' className={`text-dark fw-semibold d-block fs-6 ${sortConfig.key === 'initiated_date' ? 'pe-2' : ''}`} onClick={(e) => e.preventDefault()}>
                                            {new Date(recall.initiated_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                        </a>
                                    </td>
                                    <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6' style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link
                                            to={`/device-intelligence/recalls/${recall.product_res_number}`}
                                            state={{ searchTerm: searchTerm, currentPage: currentPage }}
                                            style={{ color: 'primary' }}
                                        >
                                            Details
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default DevicesTable;