import React from 'react';


const SortFilter = ({
    sortConfig, setSortConfig, setSortDirection, onApplySort, onResetSort

}) => {
    const handleSortChange = (key, direction) => {
        setSortConfig({ key, direction });
        setSortDirection(direction);
    }; 
    
    return (
        <div className='menu menu-sub menu-sub-dropdown w-180px w-md-230px ' data-kt-menu='true'>
            <div className='px-7 py-5'>
                <div className='fs-5 text-dark fw-bolder'>Sort by</div>
            </div>

            <div className='separator border-gray-200'></div>

            <div className='px-7 py-5'>
                <div className='mb-4'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th className="text-gray-700 fs-5 fw-bold">Field</th>
                                <th className="text-gray-700 fs-5 fw-bold">Asc</th>
                                <th className="text-gray-700 fs-5 fw-bold">Desc</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="form-check-label text-gray-600 fs-6 fw-bold">Publish Date</td>
                                <td>
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderID"
                                            id="ascId"
                                            value="asc"
                                            checked={sortConfig.key === 'publish_date' && sortConfig.direction === 'asc'}
                                            onChange={(e) => handleSortChange('publish_date', 'asc')}
                                        />
                                    </div>
                                </td>
                                <td >
                                    <div className="form-check form-check-custom form-check-solid form-check-inline form-check-sm ">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="sortOrderID"
                                            id="descId"
                                            value="desc"
                                            checked={sortConfig.key === 'publish_date' && sortConfig.direction === 'desc'}
                                            onChange={() => handleSortChange('publish_date', 'desc')}
                                        />
                                    </div>
                                </td>
                            </tr>

                        </tbody>
                    </table>
                   
                </div>


                <div className='d-flex justify-content-end'>
                    <button
                        type='reset'
                        className='btn btn-sm btn-light btn-active-light-primary me-2'
                        data-kt-menu-dismiss='true'
                        onClick={onResetSort}
                    >
                        Reset
                    </button>

                    <button
                        type='button'
                        className='btn btn-sm btn-primary'
                        data-kt-menu-dismiss='true'
                        onClick={onApplySort}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SortFilter;
