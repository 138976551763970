import React from 'react'
import { useParams } from 'react-router-dom';
import Country from './Country';

function CountryWrapper() {
  const {country} = useParams();

  return (
    <>
      <Country country={country} />
    </>
  )
}

export default CountryWrapper;

