import React from 'react';
import { KTIcon } from '../../../_metronic/helpers';
import { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
//import DevicesFilter from './DevicesFilter';
import { DevicesListPagination } from './DevicesListPagination';
import DevicesTable from './DevicesTable';
import Cookies from 'js-cookie';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { MixedWidgetRecalls } from '../Home/MixedWidgetRecalls';
import HourGlass from '../Loading/HourGlassSpinner/HourGlass';
// import Highcharts from 'highcharts';
// import HighchartsReact from 'highcharts-react-official';
// import { Modal } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
// import Select from 'react-select';

const Recalls = () => {
    const [devices, setDevices] = React.useState([]);
    const location = useLocation();
    const [searchTerm, setSearchTerm] = useState(location.state ? location.state.searchTerm : '');
    const [isLoading, setIsLoading] = useState(true);
    const interests = useSelector(state => state.account.interests);
    // const [selectedState, setSelectedState] = useState(['interests', ...interests.committees]);
    // const [allAdvisoryCommittees, setAllAdvisoryCommittees] = useState([]);
    // const [advisoryCommittees, setAdvisoryCommittees] = useState([]);
    const [currentPage, setCurrentPage] = useState(location.state ? location.state.currentPage : 1);
    const [totalPages, setTotalPages] = useState(0);
    // const itemsPerPage = 10;
    const [itemsPerPage, setItemsPerPage] = useState(Number(Cookies.get('itemsPerPage')) || 10);

    const [sortConfig, setSortConfig] = useState({ key: 'initiated_date', direction: 'asc' });
    // const [filterStatus, setFilterStatus] = useState('');
    // const [filterReason, setFilterReason] = useState('');
    // const [filterSpecialty, setFilterSpecialty] = useState('');
    // const [filterCode, setFilterCode] = useState('');
    // const [filterProductType, setFilterProductType] = useState('');
    const [filterDecisionDateRange, setFilterDecisionDateRange] = useState(Cookies.get('selectedDays') || '7');
    const [committees, setCommittees] = useState({});
    // const [showModal, setShowModal] = useState(false);
    // const [selectedRecall, setSelectedRecall] = useState(null);
    const [loadSort, setLoadSort] = useState(false);
    const [days, setDays] = useState(parseInt(Cookies.get('selectedDays'), 10) || 7);
    const [specialityOptions] = useState(interests.committees);
    const [productCodeOptions, setProductCodeOptions] = useState([]);
    //const filter = false;
    const firstRenderDays = useRef(true);
    const firstRenderSearchTerm = useRef(true);

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
    const [selectedDeviceClasses, setSelectedDeviceClasses] = useState(interests.device_classes);

    const handleDeviceClassChange = (event) => {
        const { value, checked } = event.target;
        let newValue = value === 'Class 1' ? 1 : value === 'Class 2' ? 2 : 3;
        setSelectedDeviceClasses(currentClasses => {
            if (checked) {
                if (!currentClasses.includes(newValue)) {
                    return [...currentClasses, newValue];
                }
                return currentClasses;
            } else {
                return currentClasses.filter(item => item !== newValue);
            }
        });
    };

    const [specialityDropdownOpen, setSpecialityDropdownOpen] = useState(false);
    const toggleSpecialityDropdown = () => setSpecialityDropdownOpen(!specialityDropdownOpen);
    const [selectedSpecialities, setSelectedSpecialities] = React.useState(interests.committees);
    const prevSearchInputRef = useRef();

    const handleSpecialityChange = (event) => {
        const { value, checked } = event.target;

        // Handle "All" option separately
        if (value === 'All') {
            if (checked) {
                setSelectedSpecialities(specialityOptions); // Select all options
            }
            else {
                setSelectedSpecialities([]); // Deselect all options
            }
        } else {
            setSelectedSpecialities((prevSelectedSpecialities) => {
                if (checked && !prevSelectedSpecialities.includes(value)) {
                    // If selecting an option and all other options are selected, add "All" to the selection
                    const allSelected = [...prevSelectedSpecialities, value].length === specialityOptions.length;
                    return allSelected ? [...prevSelectedSpecialities, value, 'All'] : [...prevSelectedSpecialities, value];
                } else if (!checked) {
                    // If deselecting an option, also remove "All" from the selection
                    return prevSelectedSpecialities.filter((speciality) => speciality !== value && speciality !== 'All');
                }
                return prevSelectedSpecialities;
            });
        }
    };

    const [productCodeDropdownOpen, setProductCodeDropdownOpen] = useState(false);
    const toggleProductCodeDropdown = () => setProductCodeDropdownOpen(!productCodeDropdownOpen);
    const [selectedProductCodes, setSelectedProductCodes] = React.useState([]);

    const handleProductCodeChange = (event) => {
        const { value, checked } = event.target;

        // Handle "All" option separately
        if (value === 'All') {
            if (checked) {
                setSelectedProductCodes(productCodeOptions); // Select all options
            }
            else {
                setSelectedProductCodes([]); // Deselect all options
            }
        } else {
            setSelectedProductCodes((prevSelectedProductCodes) => {
                if (checked && !prevSelectedProductCodes.includes(value)) {
                    // If selecting an option and all other options are selected, add "All" to the selection
                    const allSelected = [...prevSelectedProductCodes, value].length === productCodeOptions.length;
                    return allSelected ? [...prevSelectedProductCodes, value, 'All'] : [...prevSelectedProductCodes, value];
                } else if (!checked) {
                    // If deselecting an option, also remove "All" from the selection
                    return prevSelectedProductCodes.filter((productCode) => productCode !== value && productCode !== 'All');
                }
                return prevSelectedProductCodes;
            });
        }
    };

    const dropdownRef = useRef(null);
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setDropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (dropdownOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownOpen]);


    const dropdownRef2 = useRef(null);
    const handleClickOutsideSpeciality = (event) => {
        if (dropdownRef2.current && !dropdownRef2.current.contains(event.target)) {
            setSpecialityDropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (specialityDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutsideSpeciality);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideSpeciality);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideSpeciality);
        };
    }, [specialityDropdownOpen]);

    const dropdownRef3 = useRef(null);
    const handleClickOutsideProductCode = (event) => {
        if (dropdownRef3.current && !dropdownRef3.current.contains(event.target)) {
            setProductCodeDropdownOpen(false);
        }
    };

    useEffect(() => {
        // Add when the dropdown is open and remove when closed
        if (productCodeDropdownOpen) {
            document.addEventListener('mousedown', handleClickOutsideProductCode);
        } else {
            document.removeEventListener('mousedown', handleClickOutsideProductCode);
        }

        // Cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutsideProductCode);
        };
    }, [productCodeDropdownOpen]);

    // const handleApplyFilters = () => {
    //     setCurrentPage(1);
    // };
    // const handleResetFilters = () => {
    //     setFilterStatus('');
    //     setFilterReason('');
    //     setFilterSpecialty('');
    //     setFilterCode('');
    //     setFilterProductType('');
    //     setFilterDecisionDateRange('30');
    //     setCurrentPage(1);
    // };

    // if (filter) {
    //     handleApplyFilters();
    //     handleResetFilters();
    // }

    useEffect(() => {
        Cookies.set('itemsPerPage', itemsPerPage);
    }, [itemsPerPage]);

    useEffect(() => {
        Cookies.set('selectedDays', filterDecisionDateRange);
    }, [filterDecisionDateRange]);

    useEffect(() => {
        setCurrentPage(1);
    }, [selectedDeviceClasses, selectedSpecialities, selectedProductCodes]);

    useEffect(() => {
        if (interests) {
            const newCommittees = {};
            interests.committees.forEach(committee => {
                const match = committee ? committee.match(/(.*?)\s*\((.*?)\)/) : undefined;
                if (match && match.length > 2) {
                    const key = match[2].trim();
                    const value = match[1].trim();
                    newCommittees[key] = value;
                }
            });
            setCommittees(newCommittees);
        }
    }, [interests]);

    useEffect(() => {
        const fetchProductCodes = () => {
            axios.get('/reg/v1/fda/product_codes')
                .then(response => {
                    const options = response.data.map(codes =>
                        `${codes.product_code}`,
                    );
                    setProductCodeOptions(options);
                    setSelectedProductCodes(options);
                });
        };
        fetchProductCodes();
    }, []);

    // useEffect(() => {
    //     const fetchCommittees = () => {
    //         axios.get('/reg/v1/fda/advisory_committee')
    //             .then(response => {
    //                 const options = response.data.map(committee =>
    //                     `${committee.description} (${committee.code})`,
    //                 );
    //                 setSpecialityOptions(options);
    //             });
    //     };
    //     fetchCommittees();
    // }, []);

    // const handleShow = (event) => {
    //     setSelectedRecall(event);
    //     setShowModal(true);
    // };

    // const handleClose = () => {
    //     setShowModal(false);
    //     setSelectedRecall(null);
    // };

    // const [custom, setCustom] = useState(false);
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (currentPage === 1 && devices.length > 0 && searchTerm.trim() === '') {
    //         setTotalPages(Math.ceil(devices[0].recall_count / Math.max(1, itemsPerPage)));
    //     }
    //     else if (currentPage === 1 && devices.length === 0 && searchTerm.trim() === '') {
    //         setTotalPages(0);
    //     }
    //     else if (searchTerm.trim() !== '') {
    //         setTotalPages(Math.ceil(devices[0].search_count / Math.max(1, itemsPerPage)));
    //     }
    // }, [currentPage, itemsPerPage, devices, searchTerm]);

    useEffect(() => {
        setLoadSort(true);
    }, [days]);

    useEffect(() => {
        prevSearchInputRef.current = searchTerm;
    }, [searchTerm]);

    const debounceDelay = searchTerm.trim() === '' ? 0 : (prevSearchInputRef.current === searchTerm ? 1000 : 3000);

    useEffect(() => {

        if (selectedProductCodes.length === 0) {
            return;
        }

        const delayDebounceFn = setTimeout(async () => {
            const fetchDevices = async () => {
                setIsLoading(true);
                //setLoadSort(true);

                const committees = selectedSpecialities.map(item => {
                    const match = item.match(/\(([^)]+)\)/);
                    return match ? match[1] : null;
                }).filter(Boolean);

                const sortedCommittees = committees.sort((a, b) => a.localeCompare(b));
                const sortedProductCodes = selectedProductCodes.sort((a, b) => a.localeCompare(b));
                const sortedDeviceClasses = selectedDeviceClasses.sort((a, b) => a - b);

                const filters = { device_classes: sortedDeviceClasses, committees: sortedCommittees, product_codes: sortedProductCodes };
                const filtersJson = JSON.stringify(filters);

                try {
                    if (searchTerm.trim() === '') {
                        const response = await axios.post(
                            `/reg/v1/fda/preference/recalls/pagination?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=${sortConfig.key}&sort_order=${sortConfig.direction}&days=${days}`,
                            { filter: filtersJson },
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                        if (response.data) {
                            setDevices(response.data);
                            setTotalPages(Math.ceil(response.data[0]?.total_count / Math.max(1, itemsPerPage)));
                        }
                    } else {
                        const response = await axios.post(`/reg/v1/search/search/recall?page_num=${currentPage}&page_size=${itemsPerPage}&sort_col=1&sort_order=1&search_string=${searchTerm}`,
                            { filter: filtersJson },
                            {
                                headers: {
                                    'Content-Type': 'application/json'
                                }
                            }
                        );
                        if (response.data) {
                            setDevices(response.data);
                            setTotalPages(Math.ceil(response.data[0]?.search_count / Math.max(1, itemsPerPage)));
                        }
                    }
                } catch (error) {
                    console.log('Error fetching devices:', error);
                } finally {
                    setIsLoading(false);
                    setLoadSort(false);
                }
            };

            fetchDevices();
        }, debounceDelay);

        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage, itemsPerPage, days, sortConfig.direction, sortConfig.key, searchTerm, selectedDeviceClasses, selectedSpecialities, selectedProductCodes]);

    useEffect(() => {
        if (firstRenderDays.current) {
            firstRenderDays.current = false;
            return;
        }
        setSearchTerm('');
        setCurrentPage(1);
    }, [days]);

    useEffect(() => {
        if (firstRenderSearchTerm.current) {
            firstRenderSearchTerm.current = false;
            return;
        }

        const handler = setTimeout(() => {
            setCurrentPage(1);
            if (searchTerm.trim() !== '') {
                setSortConfig({ key: '', direction: '' });
            } else {
                setSortConfig({ key: 'initiated_date', direction: 'asc' });
            }
        }, 0);

        return () => {
            clearTimeout(handler);
        };
    }, [searchTerm]);

    // useEffect(() => {
    //     setLoadSort(true);
    //     let sortedDevices = [...devices];
    //     sortedDevices = sortedDevices.sort((a, b) => {
    //         let aValue = a[sortConfig.key];
    //         let bValue = b[sortConfig.key];

    //         if (sortConfig.key === 'fulldate') {
    //             aValue = new Date(aValue).getTime();
    //             bValue = new Date(bValue).getTime();
    //         } else if (typeof aValue === 'string' && typeof bValue === 'string') {
    //             aValue = aValue.toLowerCase();
    //             bValue = bValue.toLowerCase();
    //         }

    //         if (aValue < bValue) {
    //             return sortConfig.direction === 'asc' ? -1 : 1;
    //         }
    //         if (aValue > bValue) {
    //             return sortConfig.direction === 'asc' ? 1 : -1;
    //         }
    //         return 0;
    //     });
    //     setDevices(sortedDevices);
    //     setLoadSort(false);
    //     // eslint-disable-next-line
    // }, [sortConfig]);

    // useEffect(() => {
    //     setCurrentPage(1);
    // }, [filterStatus, filterReason, filterSpecialty, filterCode, filterDecisionDateRange, filterProductType]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // const matchesSearchTerm = (recall, searchTerms) =>
    //     searchTerms.some(term =>
    //         recall.product_res_number?.toLowerCase().includes(term) ||
    //         recall.product_description?.toLowerCase().includes(term) ||
    //         recall.recalling_firm?.toLowerCase().includes(term) ||
    //         recall.product_code?.toLowerCase().includes(term) ||
    //         recall.root_cause_description?.toLowerCase().includes(term) ||
    //         recall.recall_status?.toLowerCase().includes(term) ||
    //         recall.openfda_medical_specialty_description?.toLowerCase().includes(term) ||
    //         recall.openfda_device_name?.toLowerCase().includes(term)
    //     );

    // const matchesCommittee = (recall, committees) =>
    //     committees.some(committee => {
    //         const code = committee.split('(')[0].trim();
    //         return code === recall.openfda_medical_specialty_description;
    //     });

    // const filteredDevices = devices.filter(device => {
    //     const searchTerms = searchTerm.toLowerCase().split(/[\s,]+/).filter(Boolean);
    //     const matchesSearch = !searchTerm || matchesSearchTerm(device, searchTerms);
    //     const matchesInterests = (selectedSpecialities.length === 0 || matchesCommittee(device, selectedSpecialities)) &&
    //         (selectedDeviceClasses.length === 0 || selectedDeviceClasses.includes(parseInt(device.openfda_device_class)));
    //     // Apply filters
    //     const matchesStatus = !filterStatus || device.recall_status.toLowerCase().includes(filterStatus.toLowerCase());
    //     const matchesReason = !filterReason || device.root_cause_description.toLowerCase().includes(filterReason.toLowerCase());
    //     const matchesSpecialty = !filterSpecialty || (device.openfda_medical_specialty_description && device.openfda_medical_specialty_description.toLowerCase().includes(filterSpecialty.toLowerCase()));
    //     const matchesCode = !filterCode || device.product_code.toLowerCase().includes(filterCode.toLowerCase());
    //     const matchesProductType = !filterProductType || (device.openfda_device_name && device.openfda_device_name.toLowerCase().includes(filterProductType.toLowerCase()));
    //     const currentDate = new Date();
    //     const decisionDate = new Date(device.fulldate);
    //     decisionDate.setHours(currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds());
    //     const matchesDecisionDateRange = !filterDecisionDateRange || (decisionDate >= new Date(Date.now() - Number(filterDecisionDateRange) * 24 * 60 * 60 * 1000));
    //     return matchesSearch && matchesInterests && matchesStatus && matchesReason && matchesSpecialty && matchesCode && matchesDecisionDateRange && matchesProductType;
    // });

    // useEffect(() => {
    //     setTotalPages(Math.ceil(filteredDevices.length / Math.max(1, itemsPerPage)));
    //     if (currentPage > totalPages) {
    //         setCurrentPage(1);
    //     }
    // }, [filteredDevices, currentPage, totalPages, itemsPerPage]);

    const handleHeaderClick = (key) => {
        let direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });
    };

    // const processDataForDaysVsDeviceClass = (data) => {
    //     const deviceCounts = data.reduce((acc, item) => {
    //         const deviceclass = item.openfda_device_class;
    //         if (!acc[deviceclass]) {
    //             acc[deviceclass] = 0;
    //         }
    //         acc[deviceclass]++;
    //         return acc;
    //     }, {});

    //     const seriesData = Object.entries(deviceCounts).map(([name, count]) => ({
    //         name,
    //         data: [count] // Highcharts expects an array of data even for a single value
    //     }));

    //     return seriesData;

    // };

    // const MyChartComponent = ({ data }) => {
    //     const chartOptions = {
    //         chart: {
    //             type: 'column'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: [{
    //             min: 0,
    //             title: {
    //                 text: 'Device Class'
    //             },
    //             labels: {
    //                 enabled: false // This will hide the x-axis labels
    //             },
    //         }],
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Recalls'
    //             }
    //         },
    //         tooltip: {
    //             formatter: function () {
    //                 return `Class ${this.series.name}: ${this.y}`;
    //             }
    //         },
    //         series: processDataForDaysVsDeviceClass(data)
    //     };
    //     return (
    //         <div className={`card`} >
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Recalls</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     )
    // };

    // const processDataForDeviceClassVsAdvisoryCommittee = (data) => {
    //     const result = {};
    //     data.forEach(item => {
    //         if (item.openfda_device_class && item.openfda_device_class > 0) {
    //             const deviceClass = `Class ${item.openfda_device_class}`;
    //             const committee = item.openfda_medical_specialty_description;
    //             if (!result[deviceClass]) {
    //                 result[deviceClass] = {};
    //             }
    //             if (!result[deviceClass][committee]) {
    //                 result[deviceClass][committee] = 0;
    //             }
    //             result[deviceClass][committee]++;
    //         }
    //     });

    //     const seriesData = Object.keys(result).map(deviceClass => ({
    //         name: deviceClass,
    //         data: Object.values(result[deviceClass]),
    //         stack: 'deviceClass'
    //     }));

    //     const categories = [...new Set(data.map(item => item.openfda_medical_specialty_description))];

    //     return { seriesData, categories };
    // };

    // const DeviceClassVsAdvisoryCommitteeChart = ({ data }) => {
    //     const { seriesData, categories } = processDataForDeviceClassVsAdvisoryCommittee(data);
    //     const chartOptions = {
    //         chart: {
    //             type: 'bar'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: {
    //             categories: categories
    //         },
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Recalls'
    //             }
    //         },
    //         plotOptions: {
    //             bar: {
    //                 stacking: 'normal'
    //             }
    //         },
    //         series: seriesData
    //     };
    //     return (
    //         <div className={`card`} >
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Device Class vs. Specialty</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     );
    // };

    // const extractAbbreviation = (description) => {
    //     for (const specialty of selectedSpecialities) {
    //         const match = specialty.match(/^(.*) \(([^)]+)\)$/);
    //         if (match) {
    //             const [fullDescription, abbreviation] = match.slice(1, 3);
    //             if (fullDescription.trim() === description.trim()) {
    //                 return abbreviation;
    //             }
    //         }
    //     }
    //     return null;
    // };

    // const processDataForAdvisoryCommitteeVsDays = (data) => {
    //     const committeeCounts = data.reduce((acc, item) => {
    //         const committee = extractAbbreviation(item.openfda_medical_specialty_description);
    //         if (!acc[committee]) {
    //             acc[committee] = 0;
    //         }
    //         acc[committee]++;
    //         return acc;
    //     }, {});

    //     // Convert the counts object to an array suitable for Highcharts
    //     const seriesData = Object.entries(committeeCounts).map(([name, count]) => ({
    //         name,
    //         data: [count] // Highcharts expects an array of data even for a single value
    //     }));
    //     // const categories = [...new Set(data.map(item => item.review_advisory_committee))];
    //     return { seriesData };
    // };

    // const AdvisoryCommitteeVsDaysChart = ({ data }) => {

    //     const { seriesData } = processDataForAdvisoryCommitteeVsDays(data);
    //     const chartOptions = {
    //         chart: {
    //             type: 'column'
    //         },
    //         title: {
    //             text: ''
    //         },
    //         xAxis: [{
    //             title: {
    //                 text: 'Specialty'
    //             },
    //             labels: {
    //                 enabled: false // This will hide the x-axis labels
    //             },
    //         }],
    //         yAxis: {
    //             min: 0.1,
    //             type: 'logarithmic',
    //             title: {
    //                 text: 'Number of Recalls'
    //             }
    //         },
    //         tooltip: {
    //             formatter: function () {
    //                 return `${this.series.name}: ${this.y}`;
    //             }
    //         },
    //         series: seriesData
    //     };
    //     return (
    //         <div className={`card`}>
    //             <div className='card-header border-0 py-5'>
    //                 <h3 className='card-title align-items-start flex-column'>
    //                     <span className='card-label fw-bold fs-3 mb-1'>Recalls Specialty</span>
    //                 </h3>
    //             </div>
    //             <div className='card-body d-flex flex-column'>
    //                 <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    //             </div>
    //         </div >
    //     );
    // };


    return (
        <div>
            <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                <div className='d-flex flex-column flex-column-fluid'>
                    <div className="d-flex flex-row-fluid px-1">
                        <div className="d-flex flex-column-auto align-items-center justify-content-center ">
                            <h1 className="d-md-block text-wrap ms-1" style={{ color: "#4d4d4d" }}>
                                <div className="d-flex align-items-center ms-4">
                                    <KTIcon iconName='parcel-tracking' iconType="duotone" className='fw-bold fs-1 mx-3 text-primary ' />
                                    <span>Recalls</span>
                                    {/* {isLoading && (
                                        <div className="spinner-border text-primary ms-3" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    )} */}
                                </div>
                            </h1>
                        </div>
                    </div>
                    <div className="d-flex flex-row-fluid px-10 align-items-center">
                        <h4 className="d-md-block text-wrap mt-1 text-muted">Based on user preferences</h4>
                    </div>
                    {committees && interests.device_classes && (
                        <div className="d-flex flex-row-fluid px-10">
                            {Object.keys(committees).length > 0 && (
                                <div style={{ borderRight: interests.device_classes.length > 0 ? '1px solid #4d4d4d' : 'none', paddingRight: '7px', height: '20px' }}>
                                    <h6 className={`d-block text-wrap mt-1`}>Specialties: {' '}
                                        {Object.entries(committees).map(([key, value], index) => (
                                            <OverlayTrigger
                                                key={index}
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-${index}`}>
                                                        {value}
                                                    </Tooltip>
                                                }
                                            >
                                                <span>{index > 0 && ', '}{key}</span>
                                            </OverlayTrigger>
                                        ))}
                                    </h6>
                                </div>
                            )}
                            {interests.device_classes.length > 0 && (
                                <div style={{ paddingLeft: Object.keys(committees).length === 0 ? '0px' : '7px' }}>
                                    <h6 className={`d-block mt-1`}>Device Classes: {interests.device_classes.join(', ')}</h6>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {/* <div className="d-flex flex-column px-10 align-items-start">
                    {(filterStatus || filterReason || filterSpecialty || filterCode || filterProductType || filterDecisionDateRange) && (
                        <div className="mb-n2">
                            <h6>Active Filters:</h6>
                            <ul>
                                {filterProductType && <li><h6 className="d-md-block text-wrap">Device Name: {filterProductType}</h6></li>}
                                {filterSpecialty && <li><h6 className="d-md-block text-wrap">Specialty: {filterSpecialty}</h6></li>}
                                {filterCode && <li><h6 className="d-md-block text-wrap">Product Code: {filterCode}</h6></li>}
                                {filterReason && <li><h6 className="d-md-block text-wrap">Root Cause: {filterReason}</h6></li>}
                                {filterStatus && <li><h6 className="d-md-block text-wrap">Status: {filterStatus}</h6></li>}
                                {filterDecisionDateRange && <li><h6 className="d-md-block text-wrap">Posted Date Range: Past {filterDecisionDateRange === '365' ? '1 Year' : `${filterDecisionDateRange} Days`}</h6></li>}
                            </ul>
                        </div>
                    )}
                </div> */}
                <div className="d-flex flex-column-auto d-flex align-items-center ml-auto ms-6 mt-4">
                    <ul className='nav mb-4'>
                        <li className='nav-item'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 7 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(7); setDays(7); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                7 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 30 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(30); setDays(30); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                30 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 90 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(90); setDays(90); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                90 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 180 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(180); setDays(180); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                180 Days
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 365 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(365); setDays(365); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                1 Year
                            </button>
                        </li>
                        <li className='nav-item ms-1'>
                            <button
                                className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 ${days === 0 ? 'active' : ''}`}
                                data-bs-toggle='tab'
                                onClick={() => { setFilterDecisionDateRange(0); setDays(0); }}
                                style={{
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', // Subtle box shadow
                                    // border: '1px solid rgba(0, 0, 0, 0.1)' // Very light border
                                }}
                            >
                                All
                            </button>
                        </li>
                    </ul>

                    <div className='mb-4 dropdown ms-7' ref={dropdownRef}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownMenuButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Device Class
                        </button>
                        {dropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownMenuButton'>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class1' name='deviceClass' value='Class 1' checked={selectedDeviceClasses.includes(1)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='class1' style={{ fontSize: '1.1rem' }}>Class 1</label>
                                </li>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class2' name='deviceClass' value='Class 2' checked={selectedDeviceClasses.includes(2)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2 ' htmlFor='class2' style={{ fontSize: '1.1rem' }}>Class 2</label>
                                </li>
                                <li style={{ margin: '10px 0', width: '150px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='class3' name='deviceClass' value='Class 3' checked={selectedDeviceClasses.includes(3)} onChange={handleDeviceClassChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='class3' style={{ fontSize: '1.1rem' }}>Class 3</label>
                                </li>
                            </ul>
                        )}
                    </div>

                    <div className='mb-4 dropdown ms-7' ref={dropdownRef2}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownSpecialityButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleSpecialityDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Specialty
                        </button>
                        {specialityDropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownSpecialityButton' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                <li style={{ margin: '10px 0', width: '260px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='specialityAll' name='specialityOption' value='All' checked={selectedSpecialities.length === specialityOptions.length} onChange={handleSpecialityChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='specialityAll' style={{ fontSize: '1.1rem' }}>All</label>
                                </li>
                                {specialityOptions.map((option, index) => (
                                    <li key={index} style={{ margin: '10px 0', width: '260px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id={`speciality${index}`} name='specialityOption' value={option} checked={selectedSpecialities.includes(option)} onChange={handleSpecialityChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor={`speciality${index}`} style={{ fontSize: '1.1rem' }}>{option}</label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                    <div className='mb-4 dropdown ms-7' ref={dropdownRef3}>
                        <button className='btn btn-color-muted btn-active btn-active-light-primary dropdown-toggle' type='button' id='dropdownProductCodeButton' data-toggle='dropdown' aria-haspopup='true' aria-expanded={dropdownOpen} onClick={toggleProductCodeDropdown}
                            style={{ boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)' }}
                        >
                            Product Code
                        </button>
                        {productCodeDropdownOpen && (
                            <ul className='dropdown-menu show mt-1' aria-labelledby='dropdownProductCodeButton' style={{ maxHeight: '400px', overflowY: 'auto' }}>
                                <li style={{ margin: '10px 0', width: '260px' }}>
                                    <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id='productCodeAll' name='productCodeOption' value='All' checked={selectedProductCodes.length === productCodeOptions.length} onChange={handleProductCodeChange} />
                                    <label className='form-label fw-bold ms-5 mb-2' htmlFor='productCodeAll' style={{ fontSize: '1.1rem' }}>All</label>
                                </li>
                                {productCodeOptions.map((option, index) => (
                                    <li key={index} style={{ margin: '10px 0', width: '260px' }}>
                                        <input className='form-check-input me-2 mb-2 ms-5' type='checkbox' id={`productCode${index}`} name='productCodeOption' value={option} checked={selectedProductCodes.includes(option)} onChange={handleProductCodeChange} />
                                        <label className='form-label fw-bold ms-5 mb-2' htmlFor={`productCode${index}`} style={{ fontSize: '1.1rem' }}>{option}</label>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>


                <MixedWidgetRecalls
                    className='card-xxl-stretch pt-3'
                    chartHeight='240px'
                    days={days}
                    doctype={'Recalls'}
                    activity={"all"}
                    scale="logarithmic"
                    showChart={true}
                    summaryPage={true}
                />
                {/* {!isLoading && devices.length > 0 && (
                    <div className='row g-5 justify-content-center mt-1 mb-4'>
                        <div className='col-xxl-4'>
                            <AdvisoryCommitteeVsDaysChart data={devices} />
                        </div>
                        <div className='col-xxl-4'>
                            <DeviceClassVsAdvisoryCommitteeChart data={devices} />
                        </div>
                        <div className='col-xxl-4'>
                            <MyChartComponent data={devices} />
                        </div>
                    </div>
                )} */}
                <div className={`d-flex flex-wrap gap-4 flex-row-fluid align-items-center px-8 mb-6 mt-2`}>
                    <div className="col-7">
                        <div className=" flex-column-auto align-items-center">
                            <div className="d-flex align-items-center position-relative my-1" >
                                <KTIcon iconName='magnifier' className='fs-2 position-absolute ms-6' />
                                <input
                                    type="search"
                                    id="form1"
                                    className="form-control form-control-solid w-100 ps-14 border border-1"
                                    placeholder="Search Recalls"
                                    aria-label="Search"
                                    value={searchTerm}
                                    onChange={handleSearchChange}
                                    onSubmit={(event) => event.preventDefault()}
                                    maxLength={200}
                                    style={{ backgroundColor: '#f1faff' }}
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div className="d-flex flex-column-auto d-flex align-items-center ml-auto">
                        <button
                            type='button'
                            className='btn btn-light-primary btn-active-light d-flex align-items-center justify-content-center border border-1'
                            data-kt-menu-trigger='click'
                            data-kt-menu-placement='bottom-end'
                            data-kt-menu-flip='top-end'
                        >
                            <KTIcon iconName='filter' className='fs-2' /> Filter
                        </button>
                        <DevicesFilter
                            filterStatus={filterStatus}
                            setFilterStatus={setFilterStatus}
                            filterReason={filterReason}
                            setFilterReason={setFilterReason}
                            filterSpecialty={filterSpecialty}
                            setFilterSpecialty={setFilterSpecialty}
                            filterCode={filterCode}
                            setFilterCode={setFilterCode}
                            filterDecisionDateRange={filterDecisionDateRange}
                            setFilterDecisionDateRange={setFilterDecisionDateRange}
                            filterProductType={filterProductType}
                            setFilterProductType={setFilterProductType}
                            onApplyFilters={handleApplyFilters}
                            onResetFilters={handleResetFilters}
                        />
                    </div> */}
                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center">
                        <label className="ms-15 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"

                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />


                    </div>
                </div>
            </div>
            {/* {totalPages >= 1 && ( */}
            {isLoading ? <HourGlass /> : (<DevicesTable
                loadSort={loadSort}
                currentDevices={devices}
                handleHeaderClick={handleHeaderClick}
                sortConfig={sortConfig}
                navigate={navigate}
                searchTerm={searchTerm}
                currentPage={currentPage}
            // handleShow={handleShow}
            />)}
            {/* )} */}

            {totalPages >= 1 && (
                <div className="d-flex justify-content-center">

                    <div className="d-flex flex-column-auto ms-4 d-flex align-items-center mb-5">
                        <label className="ms-2 fs-6 fw-bold text-gray-700">Items per page:</label>
                        <input
                            type="number"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(e.target.value !== '' ? Number(e.target.value) : '')}
                            className='form-control form-control-solid ms-2 border border-1'
                            style={{ width: '90px', backgroundColor: '#f1faff' }}
                        />
                    </div>

                    <DevicesListPagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />

                </div>
            )}
            {/* <Modal
                show={showModal}
                onHide={handleClose}
                dialogClassName="custom-modal-size"
                size="lg"
                centered
            >
                <div>
                    <Modal.Header closeButton>
                        <Modal.Title>Recall Details for Product Res Number: {selectedRecall?.product_res_number}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ maxHeight: 'calc(100vh - 210px)', overflowY: 'auto' }}>
                        <div className="row">
                            <div className="col-md-6">
                                {selectedRecall?.res_event_number && <p><strong>Res Event Number:</strong> {selectedRecall?.res_event_number}</p>}
                            </div>
                            <div className="col-md-6">
                                {selectedRecall?.cfres_id && <p><strong>CFRes ID:</strong> {selectedRecall?.cfres_id}</p>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <p><strong>Event Dates:</strong>
                                    <ul>
                                        <li><strong>Initiated on:</strong> {new Date(selectedRecall?.event_date_initiated).toLocaleDateString('en-US', { timeZone: 'UTC' })}</li>
                                        <li><strong>Posted on:</strong> {new Date(selectedRecall?.fulldate).toLocaleDateString('en-US', { timeZone: 'UTC' })}</li>
                                        {selectedRecall?.event_date_terminated && <li><strong>Terminated on:</strong> {new Date(selectedRecall?.event_date_terminated).toLocaleDateString('en-US', { timeZone: 'UTC' })}</li>}
                                    </ul>
                                </p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                {selectedRecall?.recall_status && <p><strong>Recall Status:</strong> {selectedRecall?.recall_status}</p>}
                            </div>
                        </div>
                        {selectedRecall?.product_description && <p><strong>Product Description:</strong> {selectedRecall?.product_description}</p>}
                        <div className="row">
                            <div className="col-md-6">
                                {selectedRecall?.recalling_firm && <p><strong>Recalling Firm:</strong> {selectedRecall?.recalling_firm}</p>}
                            </div>
                            <div className="col-md-6">
                                {(selectedRecall?.city || selectedRecall?.state || selectedRecall?.postal_code) && <p><strong>Location:</strong> {`${selectedRecall?.city}, ${selectedRecall?.state} ${selectedRecall?.postal_code}`}</p>}
                            </div>
                        </div>
                        {selectedRecall?.additional_info_contact && <p><strong>Additional Info Contact:</strong> {selectedRecall?.additional_info_contact}</p>}
                        {selectedRecall?.openfda_device_name && <p><strong>OpenFDA Device Name:</strong> {selectedRecall?.openfda_device_name}</p>}
                        {selectedRecall?.openfda_medical_specialty_description && <p><strong>OpenFDA Medical Specialty Description:</strong> {selectedRecall?.openfda_medical_specialty_description}</p>}
                        <div className="row">
                            <div className="col-md-6">
                                {selectedRecall?.openfda_regulation_number && <p><strong>OpenFDA Regulation Number:</strong> {selectedRecall?.openfda_regulation_number}</p>}
                            </div>
                            <div className="col-md-6">
                                {selectedRecall?.openfda_device_class && <p><strong>OpenFDA Device Class:</strong> {selectedRecall?.openfda_device_class}</p>}
                            </div>
                        </div>
                        {selectedRecall?.distribution_pattern && <p><strong>Distribution Pattern:</strong> {selectedRecall?.distribution_pattern}</p>}
                        <div className="row">
                            <div className="col-md-6">
                                {selectedRecall?.product_quantity && <p><strong>Product Quantity:</strong> {selectedRecall?.product_quantity}</p>}

                            </div>
                            <div className="col-md-6">
                                {selectedRecall?.product_code && <p><strong>Product Code:</strong> {selectedRecall?.product_code}</p>}
                            </div>
                        </div>
                        {selectedRecall?.code_info && <p><strong>Code Info:</strong> {selectedRecall?.code_info}</p>}
                        {selectedRecall?.root_cause_description && <p><strong>Root Cause Description:</strong> {selectedRecall?.root_cause_description}</p>}
                        {selectedRecall?.reason_for_recall && <p><strong>Reason for Recall:</strong> {selectedRecall?.reason_for_recall}</p>}
                        {selectedRecall?.action && <p><strong>Action:</strong> {selectedRecall?.action}</p>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal > */}
        </div>
    );
}
export default Recalls;