import React, { useState, useEffect } from "react";
import { QAInterface } from "./QAInterface.tsx";
import clsx from "clsx";
function FileViewer({ setIsLoading, searchInput, expandAll, collapseAll, isLoading }) {
    const [content, setContent] = useState(null);
    const [originalContent, setOriginalContent] = useState('');
    // const [searchResults, setSearchResults] = useState([]);
    // const [isResultsExpanded, setIsResultsExpanded] = useState(true);
    // const [loading, setLoading] = useState(false);
    const isChatOpen = true;
    const [activeTab, setActiveTab] = useState('Document');


    useEffect(() => {
        fetch("/files/FDAPreamble.html")
            .then(response => response.text())
            .then(htmlData => {
                // Trim end of each line
                htmlData = htmlData.split("\n").map(line => line.trimEnd()).join("\n");

                // Parse HTML
                const parser = new DOMParser();
                const htmlDoc = parser.parseFromString(htmlData, "text/html");

                // Replace <pre> tags with <div> and apply styles
                const preElements = htmlDoc.getElementsByTagName("pre");
                Array.from(preElements).forEach(pre => {
                    const div = document.createElement("div");
                    div.style.fontFamily = "'Roboto', sans-serif";
                    div.style.textAlign = "justify";
                    div.style.whiteSpace = "pre-wrap"; // Preserve line breaks and whitespace, allow wrapping

                    // Remove lines containing "[Page", "____________________________________________________________________", or "-----------------------------------------------------------------------"
                    let lines = pre.innerHTML.split("\n");
                    let filteredLines = [];
                    let foundSummary = false;

                    for (let i = 0; i < lines.length; i++) {
                        let line = lines[i];

                        if (line.includes("[Page") || line.includes("____________________________________________________________________")) {
                            // Remove all newlines before the line that includes [Page
                            while (filteredLines.length > 0 && filteredLines[filteredLines.length - 1].trim() === "") {
                                filteredLines.pop();
                            }
                            // Skip the [Page] line itself
                            // Remove all newlines after the line that includes [Page
                            if (i < lines.length - 1) {
                                i++;
                                while (i < lines.length && lines[i].trim() === "") {
                                    i++;
                                }
                                i--; // Step back one line to continue processing correctly
                            }
                            continue;
                        }

                        if (line.trim().startsWith("SUMMARY")) {
                            foundSummary = true;
                        }

                        if (!foundSummary && line.includes("-----------------------------------------------------------------------")) {
                            continue;
                        }

                        filteredLines.push(line);
                    }

                    let filteredContent = filteredLines.join("\n");

                    // Apply transformations to the entire content
                    // Handle indentation: add a newline before lines that start with indentation and change single newlines to double newlines if there is an indentation after the newline
                    filteredContent = filteredContent.replace(/(\n|^)(\s+)/g, '\n$2');

                    // Add a new line before any indentation, even in the middle of a line
                    filteredContent = filteredContent.replace(/(\S)(\s{2,})/g, '$1\n$2');

                    // Change triple newlines to double newlines
                    filteredContent = filteredContent.replace(/\n{3,}/g, '\n\n');

                    // Replace backticks with double quotes
                    filteredContent = filteredContent.replace(/``/g, "\"");

                    // Bold fully capitalized text before a colon and the colon itself
                    filteredContent = filteredContent.replace(/(\b[A-Z\s]+):/g, "<strong>$1:</strong>");

                    // Italicize text after Roman numerals only if those numerals start the line
                    filteredContent = filteredContent.replace(/(^\b[IVXLCDM]+\.\s)(.*?)(\n|$)/gm, "$1<em>$2</em>$3");

                    // Add a newline before lines that start with fully capitalized text if there isn't already one
                    filteredContent = filteredContent.replace(/([^\n])(\b[A-Z\s]+:)/g, '$1\n$2');

                    // Split the content into two parts: before and after the "AGENCY" line
                    let [beforeAgency, afterAgency] = filteredContent.split(/(AGENCY.*)/s);

                    if (afterAgency) {
                        // Remove single new lines but not double new lines in the part after "AGENCY"
                        afterAgency = afterAgency.replace(/([^\n])\n(?!\s)([^\n])/g, '$1 $2');
                    }

                    // Combine the parts back together
                    filteredContent = beforeAgency + (afterAgency || '');

                    div.innerHTML = filteredContent;
                    pre.parentNode.replaceChild(div, pre);
                });

                const updatedContent = htmlDoc.documentElement.innerHTML;

                // Set the updated content
                setContent(updatedContent);
                setOriginalContent(updatedContent);
                setIsLoading(false);

                console.log(updatedContent.replace(/\n/g, '\\n'));
            })
            .catch(error => console.error("Error:", error));
    }, [setIsLoading]);


    useEffect(() => {
        if (searchInput && searchInput.length > 1) {
            // setLoading(true);
            const timeoutId = setTimeout(() => {
                const regex = new RegExp(`(${searchInput})`, 'gi');
                const highlightedContent = originalContent.replace(regex, '<mark>$1</mark>');
                setContent(highlightedContent); // Update the content state with the highlighted text

                // setLoading(false);
            }, 500); // 500ms debounce time
            return () => clearTimeout(timeoutId);
        } else {
            // If search input is cleared, reset content to original
            setContent(originalContent);
        }
    }, [searchInput, originalContent]);

    useEffect(() => {
        // Scroll to the first highlighted text after content is updated
        const firstMark = document.querySelector('mark');
        if (firstMark) {
            const scrollableParent = getScrollableParent(firstMark);
            if (scrollableParent) {
                const markOffsetTop = firstMark.offsetTop;
                scrollableParent.scrollTo({ top: markOffsetTop - scrollableParent.offsetTop, behavior: 'smooth' });
            }
        }
    }, [content]);

    // Helper function to find the closest scrollable ancestor
    function getScrollableParent(element) {
        let parent = element.parentNode;
        while (parent) {
            const overflowY = window.getComputedStyle(parent).overflowY;
            const isScrollable = overflowY !== 'visible' && overflowY !== 'hidden';
            if (isScrollable && parent.scrollHeight > parent.clientHeight) {
                return parent;
            }
            parent = parent.parentNode;
        }
        return null;
    }

    return (
        <div className='card card-custom'>
            <div className='card-header card-header-stretch'>
                <ul
                    className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent flex-nowrap'
                    role='tablist'
                >
                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'Document' })}
                            onClick={() => setActiveTab('Document')}
                            role='tab'
                        >
                            Document
                        </h5>
                    </li>
                    <li className='nav-item fs-4'>
                        <h5
                            className={clsx('nav-link cursor-pointer fw-semibold', { 'active text-dark fw-bold': activeTab === 'LeximQ&A' })}
                            onClick={() => setActiveTab('LeximQ&A')}
                            role='tab'
                        >
                            Lexim Q&A
                        </h5>
                    </li>
                </ul>
            </div>
            <div className='card-body'>
                <div className='tab-content'>
                    <div className={clsx('tab-pane', { active: activeTab === 'Document' })}>
                        <div className="container p-3 text-wrap mx-auto vh-100 xml-file-viewer-container d-flex flex-row"> {/* Add flex-row here */}
                            <div className={`card-body text-wrap col-6`}
                                style={{
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    whiteSpace: 'pre-wrap',
                                    boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                                    maxHeight: 'calc(100vh)',
                                    flex: 1
                                }}
                                data-kt-scroll='true'
                                data-kt-scroll-height='auto'
                            >
                                {content ?
                                    <div dangerouslySetInnerHTML={{ __html: content }} className="fs-2 text-gray-900 px-15 py-5" /> :
                                    <p></p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={clsx('tab-pane', { active: activeTab === 'LeximQ&A' })}>
                        <div>
                            <div className="container p-3 text-wrap mx-auto vh-100 xml-file-viewer-container d-flex flex-row"> {/* Add flex-row here */}
                                <div className={`card-body text-wrap col-6`}
                                    style={{
                                        overflowY: 'auto',
                                        overflowX: 'hidden',
                                        whiteSpace: 'pre-wrap',
                                        boxShadow: '1px 0 1px -1px rgba(0,0,0,0.25)',
                                        maxHeight: 'calc(100vh)',
                                        flex: 2
                                    }}
                                    data-kt-scroll='true'
                                    data-kt-scroll-height='auto'
                                >
                                    {content ?
                                        <div dangerouslySetInnerHTML={{ __html: content }} className="fs-2 text-gray-900" /> :
                                        <p></p>
                                    }
                                </div>
                                <QAInterface chatIsOpen={isChatOpen} className='col-6' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FileViewer;
