import React from 'react';
import { Link } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';


const DocumentTable = ({ loading, error, filteredDocuments, handle_source_link, DOCUMENTS_PER_PAGE, currentPage, handleViewButtonPDFClick, formatDate, searchInput }) => {
    
    
    const paginatedDocuments = !loading && searchInput.trim() === ""
        ? filteredDocuments.slice((currentPage - 1) * DOCUMENTS_PER_PAGE, currentPage * DOCUMENTS_PER_PAGE)
        : filteredDocuments;

    return (
        <div className="d-flex flex-column flex-row-fluid mt-2 px-5">
            <table className="table">
                <tbody>
                    {filteredDocuments && filteredDocuments.length > 0 ?
                        (
                            paginatedDocuments.map((document, index) => (
                                <tr key={document.doc_hash}>
                                    <td colSpan="6">
                                        {/* ...rest of the code */}
                                        <div className="card card-custom card-flush" style={{ borderTop: `6px solid ${index % 2 === 0 ? '#0088cc' : '#0088cc'}` }}>
                                            {/* <div className="card-header " >
                                                <h5 className="card-title ">   </h5>
                                                <div className="card-toolbar">
                                                    
                                                    <a href={document.s3_link} className="btn h-30px ml-0 fw-bold btn-sm d-flex align-items-center justify-content-center text-primary" style={{ paddingLeft: 0 }}>
                                                        Download
                                                    </a>
                                                    <Button
                                                        variant="primary"
                                                        size="sm"
                                                        disabled={!document.s3_link}
                                                        onClick={() => handleViewButtonPDFClick(document.iddocument)}
                                                    >
                                                        View PDF
                                                    </Button>
                                                    

                                                </div>
                                            </div> */}
                                            <div className="card-body card-scroll pt-8 ">
                                                {/* Document content */}
                                                <h4 className="card-title pb-2 d-flex align-items-center" style={{ color: '#666666' }}>
                                                    <Link
                                                        to={`${document.s3_link}`}
                                                        state={{ searchInput: searchInput, currentPage: currentPage }}
                                                        className="text-decoration-underline"
                                                        style={{ color: '#0063cc' }}
                                                    >
                                                        {document.title}
                                                    </Link>
                                                </h4>


                                                <div className="d-flex align-items-center">
                                                    <h5 className="card-title  pb-2" style={{ color: '#AA4A44' }}>Document Type: <span className="" style={{ color: '#c5736d' }}>{document.documentType}</span></h5>
                                                    {/* <span className="card-title  pb-2 px-2" style={{ color: '#AA4A44' }}>|</span> */}
                                                    {/* <h5 className="card-title  pb-2" style={{ color: '#AA4A44' }}>Subtype: <span className="" style={{ color: '#c5736d' }}>{document.subtype}</span></h5>
                                                    <span className="card-title  pb-2 px-2" style={{ color: '#AA4A44' }}>|</span>
                                                    <h5 className="card-title pb-2 " style={{ color: '#AA4A44' }}>Program: <span className="" style={{ color: '#c5736d' }}>{document.program ? document.program : "None"}</span></h5> */}

                                                </div>


                                                <div className="d-flex align-items-center">
                                                    <h5 className="card-title  pb-2" style={{ color: '#014f86' }}>Source : <span className="" style={{ color: '#0077b6' }}>{handle_source_link(document.url)}</span></h5>
                                                    {/* <span className="card-title  pb-2 px-2" style={{ color: '#006633' }}>|</span>
                                                    <h5 className="card-title pb-2 " style={{ color: '#014f86' }}>Docket ID: <span className="" style={{ color: '#0077b6' }}>{document.docketId}</span></h5> */}

                                                </div>
                                                {/* <div className="d-flex align-items-center">
                                                    <h5 className="card-title  pb-2"style={{ color: '#004080' }}>Page Count: <span className=""style={{ color: '#4da6ff' }}>{document.page_count}</span></h5>


                                                </div> */}



                                                <div className="d-flex align-items-center">
                                                    <h5 className="card-title text-gray-700 pb-2">Publish Date: <span className="text-gray-600">{formatDate(document.publish_date)}</span></h5>
                                                    {/* <span className="card-title text-gray-700 pb-2 px-2">|</span>
                                                    <h5 className="card-title pb-2 text-gray-700">Comment Start Date: <span className="text-gray-600">{document.commentStartDate !== null ? (formatDate(document.commentStartDate)) : 'None'}</span></h5>
                                                    <span className="card-title text-gray-700 pb-2 px-2">|</span>
                                                    <h5 className="card-title text-gray-700 pb-2">Comment End Date: <span className="text-gray-600">{document.commentEndDate !== null ? (formatDate(document.commentEndDate)) : 'None'}</span></h5> */}
                                                </div>


                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : error ? (
                            <tr>
                                <td colSpan="7">
                                    {error.response && error.response.status === 404 ? (
                                        <div className="alert alert-danger" role="alert" >No documents are available for the selected docket.</div>
                                    ) : (
                                        <div className="alert alert-danger" role="alert">Error: {error.message}</div>
                                    )}
                                </td>
                            </tr>
                        ) : (

                            filteredDocuments !== null && filteredDocuments.length === 0 && !loading && !error && (
                                <tr>
                                    <td colSpan="7">
                                        <div className="d-flex ms-10 mb-5">
                                            <h2>Documents for this region are being added and will be available soon.</h2>
                                        </div>
                                    </td>
                                </tr>
                            )

                        )
                    }
                </tbody>
            </table>
        </div>
    );
};

export default DocumentTable;


