import React from 'react';
import { Link } from 'react-router-dom';

const DevicesTable = ({ loadSort, currentDevices, handleHeaderClick, sortConfig, navigate, searchTerm, currentPage }) => {

    const replacements = {
        '': '™',
        '': '"',
        '': '"',
        '®': '®',
        '©': '©',
        '': '—',
        '': '–',
        '': '•',
        '': '\'',
        '': '\'',
        '': '…',
        '': '¨',
        '': '‰',
        '': '‹',
        '': '›',
        '': '«',
        '': '»',
        '¿': '®',
    }

    return (

        <div className='table-responsive px-5 mb-5'>
            <table className="table table-striped table-hover table-rounded border gy-5 gs-7 align-mreport_numberle dataTable no-footer"
                style={{
                    '--bs-table-hover-bg': 'rgba(204, 229, 255, 1)',
                    '--bs-table-striped-bg': 'rgba(230, 242, 255, 1)',
                    'tableLayout': 'fixed'
                }}>
                <thead className="text-start text-muted fw-bolder fs-7 gs-0">
                    <tr className='fw-bold fs-5 text-gray-800 border-bottom-3 border-gray-200'>

                        <th style={{ cursor: 'pointer', width: '25%', marginRight: '20px' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('brand_name');
                                }
                            }}
                        >
                            Brand Name
                            <span className={`sorting-icon ${sortConfig.key === 'brand_name' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'brand_name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ cursor: 'pointer', width: '25%', marginRight: '20px' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('generic_name');
                                }
                            }}
                        >
                            Generic Name
                            <span className={`sorting-icon ${sortConfig.key === 'generic_name' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'generic_name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        {/* <th style={{ cursor: 'pointer', width: '20%', marginRight: '20px' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('manufacturer_name');
                                }
                            }}
                        >
                            Company
                            <span className={`sorting-icon ${sortConfig.key === 'manufacturer_name' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'manufacturer_name' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th> */}
                        <th className="text-center" style={{ cursor: 'pointer', width: '12%' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('openfda_medical_specialty_description');
                                }
                            }}
                        >
                            Specialty
                            <span className={`sorting-icon ${sortConfig.key === 'openfda_medical_specialty_description' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda_medical_specialty_description' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center text-nowrap" style={{ cursor: 'pointer', width: '15%' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('product_code');
                                }
                            }}
                        >
                            Product Code
                            <span className={`sorting-icon ${sortConfig.key === 'product_code' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'product_code' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className="text-center" style={{ cursor: 'pointer', width: '15%', marginRight: '20px' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('openfda_device_class');
                                }
                            }}
                        >
                            Device Class
                            <span className={`sorting-icon ${sortConfig.key === 'openfda_device_class' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'openfda_device_class' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th style={{ cursor: 'pointer', width: '25%', marginRight: '20px' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('patient_problems');
                                }
                            }}
                        >
                            Patient Problem
                            <span className={`sorting-icon ${sortConfig.key === 'patient_problems' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'patient_problems' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>

                        <th className={`text-end text-nowrap ${sortConfig.key !== 'date_received' ? 'pe-3' : ''}`} style={{ cursor: 'pointer', width: '14%', marginRight: '20px' }}
                            onClick={() => {
                                if (searchTerm.trim() === '') {
                                    handleHeaderClick('date_received');
                                }
                            }}
                        >
                            Received Date
                            <span className={`sorting-icon ${sortConfig.key === 'date_received' ? sortConfig.direction : ''}`}>
                                {' '}<i className={`bi ${sortConfig.key === 'date_received' ? (sortConfig.direction === 'asc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill') : ''}`}></i>
                            </span>
                        </th>
                        <th className='text-end' style={{ width: '11%' }}>
                            Details
                        </th>
                    </tr>
                </thead>
                {loadSort && (
                    <tbody>
                        <tr>
                            <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>Loading...</td>
                        </tr>
                    </tbody>
                )}
                {!loadSort && (
                    <tbody>
                        {(currentDevices.length === 0 && !loadSort) ? (<tr>
                            <td colSpan={9} className='text-dark fw-semibold fs-5' style={{ textAlign: 'center' }}>No records found with selected filters. Please try again with other filters.</td>
                        </tr>
                        ) : (
                            currentDevices.map((event, index) => (
                                <tr className='align-items-center bg-hover-light-primary' onClick={() => navigate(`/device-intelligence/adverseevents/${event.report_number}`, {
                                    state: { searchTerm: searchTerm, currentPage: currentPage }
                                })}>
                                    <td>
                                        <a href='/device-intelligence/adverseevents' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {event.brand_name ? Object.keys(replacements).reduce((str, key) => {
                                                return str.replace(new RegExp(key, 'g'), replacements[key]);
                                            }, event.brand_name) : 'Unknown Brand'}

                                        </a>
                                    </td>
                                    <td style={{
                                        verticalAlign: 'mreport_numberle',
                                        maxWidth: '150px',
                                        overflow: 'hreport_numberen',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                    }} className='text-dark fw-semibold fs-6 text-wrap' onClick={(e) => e.preventDefault()}>
                                        {event.generic_name}
                                    </td>
                                    {/* <td>
                                        <a href='/device-intelligence/adverseevents' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {event.manufacturer_name}
                                        </a>
                                    </td> */}
                                    <td>
                                        <a href='/device-intelligence/adverseevents' className='text-center text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {event.openfda_medical_specialty_description}
                                        </a>
                                    </td>
                                    <td className="text-dark text-wrap fw-semibold fs-6 text-center" style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link to={`/device-intelligence/classification/${event.product_code}`} style={{ color: 'primary' }} className='align-items-center text-center' target="_blank" >{event.product_code}</Link>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/adverseevents' className='text-dark fw-semibold d-block fs-6 text-center' onClick={(e) => e.preventDefault()}>
                                            {event.openfda_device_class}
                                        </a>
                                    </td>
                                    <td>
                                        <a href='/device-intelligence/adverseevents' className='text-dark fw-semibold d-block fs-6' onClick={(e) => e.preventDefault()}>
                                            {event.patient_problems}
                                        </a>
                                    </td>

                                    <td className='text-end'>
                                        <a href='/device-intelligence/adverseevents' className={`text-dark fw-semibold d-block fs-6`} onClick={(e) => e.preventDefault()}>
                                            {new Date(event.date_received).toLocaleDateString('en-US', { timeZone: 'UTC' })}
                                        </a>
                                    </td>
                                    <td className='text-end text-hover-primary text-dark text-wrap fw-semibold fs-6' style={{ cursor: 'default' }} onClick={(e) => e.stopPropagation()}>
                                        <Link
                                            to={`/device-intelligence/adverseevents/${event.report_number}`}
                                            state={{ searchTerm: searchTerm, currentPage: currentPage }}
                                            style={{ color: 'primary' }}
                                        >
                                            Details
                                        </Link>
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                )}
            </table>
        </div>
    );
}

export default DevicesTable;